import React from "react";
import {useState} from "react";
import {Card, TextArea, IconButton, Spinner, TextField} from "@nike/eds";
import {ZplPreviewForm} from "../model/ZplPreviewForm";
import {ZPLPreviewService} from "../service/ZPLPreviewService";

export function ZPLViewer(props): JSX.Element {

    const zplPreviewService: ZPLPreviewService = props.zplPreviewService

    const [payloadContent, setPayloadContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [labelWidth, setLabelWidth] = useState(98);
    const [labelHeight, setLabelHeight] = useState(165);
    const [zplLabelPreviews, setZplLabelPreviews] = useState<string[]>([])

    const payloadContentChangeHandler = (event) => {
        setPayloadContent(event.target.value)
    }

    const submitHandler = (event) => {
        event.preventDefault();
        setLoading(true)
        let searchRequestData: ZplPreviewForm = {
            payloadContent: payloadContent,
            labelWidth: labelWidth,
            labelHeight: labelHeight,
        }

        zplPreviewService.generatePreview(searchRequestData).then((response) => {
            if (response.ok) {
                response.text().then((text) => {
                    let jsonObj = JSON.parse(text);
                    setZplLabelPreviews(jsonObj);
                });
            } else {
                console.log("something went wrong")
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const labelWidthChangeHandler = (event) => {
        setLabelWidth(event.target.value)
    }

    const labelHeightChangeHandler = (event) => {
        setLabelHeight(event.target.value)
    }

    return (
        <div>
            <form onSubmit={submitHandler}>
                <Card>
                    <div className="eds-grid eds-grid--m-cols-6 eds-gap--16">
                        <div className="searchField eds-grid--m-row-1" style={{width: 300}}>
                            <TextField
                                    onChange={labelWidthChangeHandler}
                                    type="number"
                                    defaultValue={98}
                                    id="labelWidthSelect"
                                    label="Label Width in mm"
                            />
                        </div>
                        <div className="searchField eds-grid--m-row-1" style={{minWidth: 300}}>
                            <TextField
                                    onChange={labelHeightChangeHandler}
                                    type="number"
                                    defaultValue={165}
                                    id="labelHeightSelect"
                                    label="Label Height in mm"
                            />
                        </div>
                        <div
                            className="eds-grid--m-col-4 eds-grid--m-row-1 eds-spacing--p-12 eds-grid--item-align-start eds-grid--item-justify-end"
                            style={{"display": "flex"}}>
                            { loading ?
                                <div style={{padding: '6px', backgroundColor: 'gray', borderRadius: '50%', marginRight: "5px"}} className="eds--dark">
                                    <Spinner color="white"/>
                                </div>
                                :
                                <IconButton
                                    icon="Play"
                                    type={"submit"}
                                    size={"small"}
                                    style={{marginRight: "5px"}}
                                    label={""}/>
                            }
                            <IconButton
                                onClick={() => window.location.reload()}
                                icon="Undo"
                                size={"small"}
                                style={{marginRight: "5px"}}
                                label={""}/>
                        </div>
                        <br/>
                    </div>

                    <div className="eds-grid eds-grid--m-cols-2 eds-gap--16">
                        <div>
                            <TextArea onChange={payloadContentChangeHandler}
                                      id="payloadContent"
                                      label=""
                                      placeholder="ZPL content"
                                      minRows={30}
                                      maxRows={30}
                                      autoResize
                            />
                        </div>
                        <div style={{paddingLeft: "30px", overflowX: "scroll"}} className="eds-flex eds-flex--direction-row eds-spacing--p-16">
                            {zplLabelPreviews.map(labelPreview => (
                                <img src={`data:image/png;base64, ${labelPreview}`} style={{borderColor:"black", border: "3px", borderStyle: "solid", marginRight: "8px"}} height={"750px"} alt="ZPL Preview"/>
                            ))}
                        </div>
                    </div>

                </Card>

            </form>
        </div>
    );
}
