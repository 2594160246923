import "@nike/eds/dist/index.css";
import {TestPrint} from "./components/TestPrint";
import {ZPLViewer} from "./components/ZPLViewer";
import {Route, Routes} from 'react-router-dom'
import React from "react";
import {SimulationService} from "./service/SimulationService";
import {SideBar} from "./components/shared/SideBar";
import {ZPLPreviewService} from "./service/ZPLPreviewService";

const simulationService: SimulationService = new SimulationService()
const zplPreviewService: ZPLPreviewService = new ZPLPreviewService()

function App() {

    return (
        <div className="fillWidth fillHeight eds-flex eds-flex--direction-row">
            <SideBar/>
            <div className="fillWidth fillHeight" style={{overflowX: "hidden", overflowY: "scroll"}}>
                <div className="mainContentLayout">
                    <Routes>
                        <Route path="/simulation/simulate" element={<TestPrint simulationService={simulationService}/>}/>
                        <Route path="/simulation/zpl-viewer" element={<ZPLViewer zplPreviewService={zplPreviewService}/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default App;
