import React, {useEffect, useState} from "react";
import {VerticalNavbar, setActiveValueInNavItems, NavItem} from "@nike/eds";
import {useLocation, useNavigate} from "react-router-dom";
import {URL_PREVIEW, URL_SIMULATE, URL_TOP} from "./Constants";

export function SideBar(props) {

    const MENU_ID_SIMULATE = "SIMULATE"
    const MENU_ID_ZPL_PREVIEW = "ZPL_PREVIEW"

    const location = useLocation();

    const [items, setItems] = useState<Array<NavItem>>([
        {
            id: MENU_ID_SIMULATE,
            icon: "Play",
            label: "Simulate",
            active: getCurrentActiveId() === MENU_ID_SIMULATE,
        },
        {
            id: MENU_ID_ZPL_PREVIEW,
            icon: "Show",
            label: "ZPL Preview",
            active: getCurrentActiveId() === MENU_ID_ZPL_PREVIEW,
        }
    ]);

    function getCurrentActiveId(): string | undefined {
        if (location.pathname.startsWith(URL_SIMULATE) || location.pathname === URL_TOP) {// Simulate is default page){
            return MENU_ID_SIMULATE;
        } else if (location.pathname.startsWith(URL_PREVIEW)) {
            return MENU_ID_ZPL_PREVIEW;
        }
    }

    const navigate = useNavigate();

    useEffect(() => {
        const activeId: string | undefined = getCurrentActiveId();
        if (activeId) {
            const newItems = setActiveValueInNavItems(activeId, items);
            if (newItems) {
                setItems(newItems);
            }
        }
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleClick = item => {
        const newItems: Array<NavItem> | undefined = setActiveValueInNavItems(item.id, items);
        if (newItems) {
            setItems(newItems);
        }
        switch (item.id) {
            case MENU_ID_SIMULATE: {
                navigate(URL_SIMULATE)
                break;
            }
            case MENU_ID_ZPL_PREVIEW: {
                navigate(URL_PREVIEW)
                break;
            }
        }
    };

    return (
        <VerticalNavbar id="sideBar"
                        navLogoSlot={null}// Default is the Nike logo which is already in the header
                        appName="Simulation"
                        appSubtitle=""
                        onNavigate={handleClick}
                        items={items}
        />
    )
}