import {oktaAuth} from "../components/config/OktaConfig";
import {ZplPreviewForm} from "../model/ZplPreviewForm";

export class ZPLPreviewService {

    generatePreview(zplPreviewFormData: ZplPreviewForm) {
        const apiAddress: string | undefined = process.env.REACT_APP_ZPL_PREVIEW_API_ENDPOINT;
        const token = oktaAuth.getAccessToken();

        if (!apiAddress) throw new Error('API address not defined')
        if (!token) throw new Error('No token found for authorization')

        return fetch(apiAddress + "/preview", {
                method: "POST",
                mode: 'cors',
                redirect: 'manual',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    height: zplPreviewFormData.labelHeight,
                    width: zplPreviewFormData.labelWidth,
                    zpl: zplPreviewFormData.payloadContent
                })
            }
        )
    }

}