import {oktaAuth} from "../components/config/OktaConfig";
import {TestPrintForm} from "../model/TestPrintForm";
import { v4 as uuidv4 } from 'uuid';

export class SimulationService {

    startSimulate(testPrintData: TestPrintForm[]) {
        const apiAddress: string | undefined = process.env.REACT_APP_SERVICE_ENDPOINT;
        const token = oktaAuth.getAccessToken();

        if (!apiAddress) throw new Error('API address not defined')
        if (!token) throw new Error('No token found for authorization')


        var data = new FormData()
        testPrintData.forEach((testPrint) => {
            if (testPrint.fileContent) {
                let fileName = uuidv4();
                data.append('file', testPrint.fileContent, fileName);
                data.append('body', JSON.stringify({
                    source: testPrint.source,
                    documentType: testPrint.documentType,
                    requestType: testPrint.requestType,
                    printStation: testPrint.printStation,
                    printerName: testPrint.printerName,
                    contentType: testPrint.contentType,
                    isFileInput: testPrint.isFileInput,
                    fileName: fileName}))
            } else {
                data.append('body', JSON.stringify({
                    source: testPrint.source,
                    documentType: testPrint.documentType,
                    requestType: testPrint.requestType,
                    payloadContent: testPrint.payloadContent,
                    printStation: testPrint.printStation,
                    printerName: testPrint.printerName,
                    contentType: testPrint.contentType,
                    isFileInput: testPrint.isFileInput}))
            }
        });

        console.log(data);

        let printHandlerUrl = this.determinePrintHandlerUrl(testPrintData[0]); // it is ok to use the first element here, because all elements have the same printHandlerUrl > enforced by requestTypeChangeHandler

        return fetch(apiAddress + "/v1/payload/" + printHandlerUrl, {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                body: data
            }
        )
    }

    determinePrintHandlerUrl(testPrintData) {
        if (testPrintData.requestType === "printCupsAdapter") {
            return testPrintData.contentType
        } else {
            return "gps"
        }
    }

    getPayload(testPrintForm: TestPrintForm): Promise<Response> {
        const apiAddress: string | undefined = process.env.REACT_APP_SERVICE_ENDPOINT;
        const token = oktaAuth.getAccessToken();

        if (!apiAddress) throw new Error('API address not defined')
        if (!token) throw new Error('No token found for authorization')
        if (!testPrintForm.source) throw new Error('No source found')
        if (!testPrintForm.documentType) throw new Error('No documentType found')
        if (!testPrintForm.requestType) throw new Error('No requestType found')

        return fetch(apiAddress + "/v1/payload/" + testPrintForm.source + "/" + testPrintForm.documentType + "/" + testPrintForm.requestType, {
                method: "GET",
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
        )
    }

}